import Alpine from 'alpinejs';

// Components
//import Choices from 'choices.js';
import "choices.js/public/assets/styles/choices.css";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// try {
//     const choices = new Choices('#choices-location');
// } catch (error) {

// }

Fancybox.bind("[data-fancybox]", {
    // Your custom options
});
// Global
window.Alpine = Alpine;

// Inits
Alpine.start();