// Change success and error messages for all forms on this page
document.addEventListener('freeform-ready', function (event) {
    // Customize the error and success messages
    event.options.successBannerMessage = 'Gracias por contactarnos, nos pondremos en contacto contigo lo mas pronto posible.';
    event.options.errorBannerMessage = 'Hubo un error al enviar el formulario, por favor intenta mas tarde.';
});


// // Find the form element
// var form = document.getElementById('contact-form');

// // Add the on-submit event listener
// form.addEventListener('submit', ajaxifyForm, false);

// function ajaxifyForm(event) {
//     var form = event.target;
//     var data = new FormData(form);

//     var method = form.getAttribute('method');
//     var action = form.getAttribute('action');

//     var request = new XMLHttpRequest();
//     request.open(method, action ? action : window.location.href, true);
//     request.setRequestHeader('Cache-Control', 'no-cache');

//     // Set the Craft specific AJAX headers
//     request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
//     request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest');

//     request.onload = function () {
//         if (request.status === 200) {
//             var response = JSON.parse(request.response);

//             if (response.success && response.finished) {
//                 form.reset();
//                 alert('Form posted successfully');
//             } else if (response.errors || response.formErrors) {
//                 console.log('Field Errors', response.errors);
//                 console.log('Form Errors', response.formErrors);

//                 alert('Form failed to submit');
//             }

//             // Update the Honeypot field if using Javascript Test
//             if (response.honeypot) {
//                 var honeypotInput = form.querySelector(
//                     'input[name^=freeform_form_handle_]'
//                 );
//                 honeypotInput.setAttribute('name', response.honeypot.name);
//                 honeypotInput.setAttribute('id', response.honeypot.name);
//                 honeypotInput.value = response.honeypot.hash;
//             }
//         } else {
//             console.error(request);
//         }
//     };

//     request.send(data);
//     event.preventDefault();
// }
